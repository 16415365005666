import React from 'react';

import { CalculatorIcon, DaftAssistIcon, SafeIcon } from '@dsch/icons';
import { AdSlot } from '@dsch/react-dfp';
import styled from 'styled-components';

import ClickablePanel from '../../components/Toolkit/ClickablePanel/ClickablePanel';

import { SECTIONS } from '../../types';
import { DFPContainer } from '../Toolkit/DFPContainer/DFPContainer';
import { DFPUnitSizes, DFPScreenSizes } from '../Toolkit/DFPContainer/DFPSizes';
const {
  BILLBOARD,
  LEADERBOARD,
  LEADERBOARD_LARGE,
  LEADERBOARD_XL,
  MPU,
  MPU_300,
  SKYSCRAPER,
  SKYSCRAPER_SLIM,
  SKYSCRAPER_WIDE,
} = DFPUnitSizes;

const { VIEW_DESKTOP } = DFPScreenSizes;

type SideColumnProps = {
  reactDFPAdsReadyToLoad?: boolean;
  section: SECTIONS;
};

type SectionProps = {
  id:
    | 'daft_mortgages'
    | 'daft_checklist'
    | 'stay_safe'
    | 'advantageAd_market'
    | 'advantageAd_sale';
  heading: string;
  subHeading: string;
  icon: string;
  url: string;
  campaign: boolean;
};

type SectionPanelProps = {
  [key: string]: {
    utmCampaign?: string;
    panels: SectionProps[];
  };
};

const StickySide = styled.div`
  height: calc(100% - 205px);
  @media (min-height: 636px) {
    .StickySideInner {
      position: sticky;
      top: ${({ theme }) => theme.spacing.S4};
    }
  }
`;

const StickySideTopDFP = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

const TopPanelsWrapper = styled.div`
  @media only screen and (max-width: 898px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto ${({ theme }) => theme.spacing.S4};
  max-width: 300px;
`;

export const IconPanel = styled.img`
  margin: ${({ theme }) => `0 ${theme.spacing.M16} 0 0`};
`;

const Icons: { [key: string]: JSX.Element } = {
  CALCULATOR: <CalculatorIcon />,
  DAFTASSIST: <DaftAssistIcon />,
  BUYINGCHECKLIST: (
    <img src="/static/images/circle_check.svg" alt="BUYING_CHECKLIST" />
  ),
  SALEPANEL: (
    <IconPanel src="/static/images/AA_sale.svg" alt="How to sell a home" />
  ),
  MARKETPANEL: (
    <IconPanel src="/static/images/AA_icon.svg" alt="Market your home" />
  ),
  SAFE: <SafeIcon />,
};

const SideColumn = (props: SideColumnProps) => {
  const advantageAdHowToSellPanel: SectionProps = {
    id: 'advantageAd_sale',
    heading: 'How to sell a home',
    subHeading: 'Learn about the steps involved',
    icon: 'SALEPANEL',
    url: 'https://www.sell.daft.ie/?utm_source=Daft&utm_medium=SRP+sales+side+panels&utm_campaign=AA+Signposting+-+Sell+section',
    campaign: false,
  };

  const advantageAdMarketPanel: SectionProps = {
    id: 'advantageAd_market',
    heading: 'Market your home with a Daft Advantage Ad',
    subHeading: 'Unbeatable visibility, more offers, best price',
    icon: 'MARKETPANEL',
    url: 'https://www.advantage.daft.ie/?utm_source=Daft&utm_medium=SRP+sales+side+panels&utm_campaign=AA+Signposting',
    campaign: false,
  };

  const daftMortgagesRentPanel: SectionProps = {
    id: 'daft_mortgages',
    heading: 'Curious about Buying?',
    subHeading: 'Calculate what you might afford',
    icon: 'CALCULATOR',
    url: '/daft-mortgages/buying-budget?utm_source=daft&utm_medium=SRP_panel&utm_campaign=',
    campaign: true,
  };

  const daftChecklistPanel: SectionProps = {
    id: 'daft_checklist',
    heading: 'Your Daft Buying Checklist',
    subHeading: 'Track your buying progress',
    icon: 'DAFTASSIST',
    url: '/buying-checklist?utm_source=daft&utm_medium=srp&utm_campaign=buyingchecklist',
    campaign: false,
  };

  const staySafePanel: SectionProps = {
    id: 'stay_safe',
    heading: 'Stay Safe Online',
    subHeading: 'Practical advice and tips',
    icon: 'SAFE',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003988977-Safety-Online',
    campaign: false,
  };

  const sectionPanels: SectionPanelProps = {
    [SECTIONS.BUY]: {
      utmCampaign: 'buy',
      panels: [advantageAdHowToSellPanel, advantageAdMarketPanel],
    },
    [SECTIONS.NEW_HOMES]: {
      utmCampaign: 'new_homes',
      panels: [daftMortgagesRentPanel, daftChecklistPanel],
    },
    [SECTIONS.RENT]: {
      utmCampaign: 'rent',
      panels: [staySafePanel],
    },
    [SECTIONS.SHARE]: {
      utmCampaign: 'share',
      panels: [staySafePanel],
    },
    [SECTIONS.SOLD]: {
      utmCampaign: 'sold',
      panels: [advantageAdHowToSellPanel, advantageAdMarketPanel],
    },
    [SECTIONS.COMMERCIAL_BUY]: { panels: [staySafePanel] },
    [SECTIONS.COMMERCIAL_RENT]: { panels: [staySafePanel] },
    [SECTIONS.STUDENT_ACCOMMODATION_RENT]: { panels: [staySafePanel] },
    [SECTIONS.STUDENT_ACCOMMODATION_SHARE]: { panels: [staySafePanel] },
    [SECTIONS.PARKING_BUY]: { panels: [staySafePanel] },
    [SECTIONS.PARKING_RENT]: { panels: [staySafePanel] },
    [SECTIONS.INTERNATIONAL_BUY]: { panels: [staySafePanel] },
    [SECTIONS.INTERNATIONAL_RENT]: { panels: [staySafePanel] },
    [SECTIONS.HOLIDAY_HOMES]: { panels: [staySafePanel] },
  };

  const getTopPanels = (section: SECTIONS) => {
    return sectionPanels[section]?.panels.map((item: SectionProps) => (
      <ClickablePanel
        key={item.id}
        IconComponent={Icons[item.icon]}
        content={{
          heading: item.heading,
          subHeading: item.subHeading,
        }}
        dataTracking={section}
        id={item.id}
        href={`${item.url}${
          sectionPanels[section]?.utmCampaign && item.campaign
            ? sectionPanels[section]?.utmCampaign
            : ''
        }`}
      />
    ));
  };
  return (
    <>
      {sectionPanels[props.section] && (
        <TopPanelsWrapper>{getTopPanels(props.section)}</TopPanelsWrapper>
      )}
      <StickySide>
        <div className="StickySideInner">
          <StickySideTopDFP>
            {props.reactDFPAdsReadyToLoad && (
              <DFPContainer
                AdSlot={AdSlot}
                adUnit="daft/df_sp_search_side"
                disableBackground
                reactDFPAdsReadyToLoad={props.reactDFPAdsReadyToLoad}
                sizeMapping={[
                  {
                    viewport: [940, 0],
                    sizes: [
                      SKYSCRAPER_WIDE,
                      MPU,
                      MPU_300,
                      SKYSCRAPER,
                      SKYSCRAPER_SLIM,
                    ],
                  },
                  {
                    viewport: [704, 0],
                    sizes: [SKYSCRAPER, SKYSCRAPER_SLIM],
                  },
                ]}
                sizes={[
                  BILLBOARD,
                  LEADERBOARD,
                  LEADERBOARD_XL,
                  LEADERBOARD_LARGE,
                  SKYSCRAPER,
                  SKYSCRAPER_SLIM,
                  SKYSCRAPER_WIDE,
                  MPU,
                  MPU_300,
                ]}
                slotId="df_sp_search_side"
              />
            )}
          </StickySideTopDFP>
          <DFPContainer
            AdSlot={AdSlot}
            adUnit="daft/df_sp_search_side_lower"
            disableBackground
            reactDFPAdsReadyToLoad={props.reactDFPAdsReadyToLoad}
            sizeMapping={[
              {
                viewport: VIEW_DESKTOP,
                sizes: [MPU_300],
              },
            ]}
            sizes={[MPU_300]}
            slotId="df_sp_search_side_lower"
          />
        </div>
      </StickySide>
    </>
  );
};

export default SideColumn;
